import { Component, Vue, Model } from 'vue-property-decorator';
import { Report as ReportModel } from '@/models/Report';
import { Question } from '@/models/Question';
import { AxiosError } from 'axios';
import ErrorHandler from '@/support/ErrorHandler';
import { formatDate } from '@/support/String';
import { isArray } from 'lodash';

@Component<Vragenoverzicht>({})
export default class Vragenoverzicht extends Vue {
  public $pageTitle = 'Vragenoverzicht';

  @Model('report', { default: () => new ReportModel() })
  protected report!: ReportModel;

  protected isLoading = true;

  protected questions: Question[] = [];

  protected async mounted() {
    this.isLoading = true;
    await this.loadQuestions();

    this.isLoading = false;
  }

  public async loadQuestions() {
    if (! this.report.type) {
      return;
    }

    this.questions = await new Question()
      .include('answer')
      .filter({
        form_types: ['tcmg', 'expert', 'manager', 'pre_controller'],
        sections: ['1', '2', '3', '4', '5', '6', '7', '8', '12', '100', '110', '120', '130'],
        report_type: this.report.type.uuid,
        report: this.report.uuid,
      })
      .limit(300)
      .all()
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });

    // this.questions = this.questions.filter(function (question) {
    //   return question.type !== 'header';
    // });
  }

  protected parseAnswerValue(question: Question, value: string | string[]) {
    if (isArray(value)) {
      const answeredOptions: string[] = [];
      value.forEach((answer: string) => {
        answeredOptions.push((question.options as any).find((question: Question) => question.uuid === answer));
      });

      return answeredOptions.map((answer: any) => {
        if (answer && answer.value) return answer.value;
      }).toString();
    }

    if (question.type === 'date') {
      return value ? formatDate(value) : '';
    }

    return value;
  }
}

interface QuestionFilter {
  form_types?: string[];
  sections?: string[];
  report_type?: string;
  report?: string;
}
