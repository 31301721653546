var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "elementPanel" },
    [
      _c(
        "v-layout",
        { attrs: { row: "", wrap: "" } },
        [
          _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _vm.isLoading
                ? _c("LoaderCard", {
                    attrs: {
                      flat: "",
                      type: "spinner--center",
                      minHeight: "300px"
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      !_vm.isLoading
        ? [
            !_vm.isLoading
              ? _c(
                  "v-layout",
                  [
                    _c(
                      "v-flex",
                      { attrs: { xs12: "" } },
                      [
                        _c(
                          "v-layout",
                          {
                            staticClass: "item__list item__list--reports",
                            attrs: { wrap: "" }
                          },
                          [
                            _vm._l(_vm.questions, function(question, index) {
                              return [
                                question.type === "header"
                                  ? [
                                      _c(
                                        "v-flex",
                                        {
                                          key: "questions-header-" + index,
                                          attrs: { xs12: "" }
                                        },
                                        [
                                          _c(
                                            "v-layout",
                                            [
                                              _c(
                                                "v-flex",
                                                { attrs: { xs12: "" } },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "font-weight-bold headline primary--text pa-2"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(question.label)
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  : [
                                      _c(
                                        "v-flex",
                                        {
                                          key: "questions-" + index,
                                          staticClass: "list__item",
                                          attrs: { xs12: "" }
                                        },
                                        [
                                          _c(
                                            "v-layout",
                                            {
                                              attrs: {
                                                wrap: "",
                                                "align-center": ""
                                              }
                                            },
                                            [
                                              _c(
                                                "v-flex",
                                                { attrs: { xs6: "" } },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(question.label)
                                                    )
                                                  ])
                                                ]
                                              ),
                                              _c(
                                                "v-flex",
                                                { attrs: { xs6: "" } },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "withLineBreak font-weight-bold"
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.parseAnswerValue(
                                                              question,
                                                              question.answer
                                                                .value
                                                                ? question
                                                                    .answer
                                                                    .value
                                                                : ""
                                                            )
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ]
                              ]
                            })
                          ],
                          2
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e()
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }